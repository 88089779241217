'use client';

import AnchorNavigation from 'components/AnchorNavigation';
import dynamic from 'next/dynamic';
import { useEffect, useState } from 'react';

import type { IBreadcrumbTitles } from 'components/Breadcrumbs';
import type { ISearchOverlay } from 'components/HeaderNavigation/components/MobileNavigation';
import type { DictionaryItem } from 'service/dictionary/dictionaryService';
import type { RecipeLandingPageInterface } from 'types/Recipes';

import getSearchClient from 'server/repository/algolia/client';
import TemplateMapper from 'templates/TemplateMapper';
import { TrackingService } from 'service/tracking/trackingService';
import { useGlobalContext } from 'components/Context/hooks/useGlobalContext';

const Breadcrumbs = dynamic(
  () => import('components/Breadcrumbs').then((m: any) => m.Breadcrumbs),
  { ssr: true },
) as any;

const IntroText = dynamic(() => import('components/IntroText').then((m) => m.IntroText), {
  ssr: true,
});

const CategorySubcategory = dynamic(
  () => import('components/CategorySubcategory').then((m: any) => m.CategorySubcategory),
  { ssr: true },
) as any;

const SliderCarousel = dynamic(
  () => import('components/SliderCarousel').then((m) => m.SliderCarousel),
  { ssr: true },
);

const Spacer = dynamic(() => import('components/Spacer').then((m) => m.Spacer), {
  ssr: true,
});

function ClientRecipesPage({
  landingPage,
  dynamicPageComponents,
  breadcrumbsCMSData,
  searchOverlayData,
  difficultyCopy,
  recipeCopy,
  searchItemLabels,
}: {
  landingPage: RecipeLandingPageInterface;
  dynamicPageComponents: any;
  searchOverlayData: ISearchOverlay;
  breadcrumbsCMSData: IBreadcrumbTitles;
  difficultyCopy: Record<string, string>;
  recipeCopy: DictionaryItem[];
  searchItemLabels: Record<string, string>;
}) {
  const { pathTranslations } = useGlobalContext();
  const heroCarouselData = landingPage?.recipeLandingPage?.heroCarouselComponent || null;
  const introData = landingPage?.recipeLandingPage?.introText || null;
  const anchorNavigations = landingPage?.recipeLandingPage?.anchorNavigationList?.anchorNavigations;
  const [searchOverlayDataExtended, setSearchOverlayDataExtended] = useState(searchOverlayData);

  useEffect(() => {
    if (!searchOverlayDataExtended.searchClient) {
      const algoliaClient = getSearchClient();
      setSearchOverlayDataExtended({
        ...searchOverlayDataExtended,
        searchClient: algoliaClient,
      });
    }
  }, [searchOverlayData]);

  useEffect(() => {
    TrackingService.contentGroups({
      cg1: 'recipe categories',
    });
  }, []);

  const CategorySubcategoryWrapper = ({ category, index }: any) => {
    const categoryDataDynamic = {
      productsAmount:
        category.totalRecipes > 0
          ? `${category.totalRecipes} ${landingPage?.recipesText?.value || 'Recipes'}`
          : undefined,
      id: category?.id,
      images: {
        desktop: category?.keyImage?.asset?.[0]?.desktop,
        mobile: category?.keyImage?.asset?.[0]?.mobile,
        mimeType: category?.keyImage?.asset?.[0]?.mimeType,
      },
      promoImage: {
        desktop: category?.promoImage?.desktop?.[0]?.url,
        mobile: category?.promoImage?.mobile?.[0]?.url,
        mimeType: category?.promoImage?.mobile?.[0]?.mimeType,
      },
      title: category?.name,
      link: {
        url: `${pathTranslations.recipesCategory}${category?.slug}`,
        displayName: landingPage?.learnMoreText?.value || 'Learn More',
      },
      button: landingPage?.learnMoreText?.value || 'Learn More',
    };
    const subcategoryCardsData = category?.recipeSubcategories?.map((subCategory: any) => {
      const amount = subCategory?.totalRecipes || 0;
      return {
        productsAmount: `${amount || 0} ${landingPage?.recipesText?.value || 'Recipes'}`,
        categoryId: subCategory?.id,
        id: subCategory?.id,
        images: {
          desktop: subCategory?.keyImage?.asset?.[0]?.desktop,
          mobile: subCategory?.keyImage?.asset?.[0]?.desktop,
        },
        title: subCategory?.recipeSubCategoryName,
        link: {
          url: `${pathTranslations.recipesSubCategory}${category?.slug}/${subCategory?.slug}`,
          displayName: landingPage?.learnMoreText?.value || 'Learn More',
        },
      };
    });
    const categoryTrackingData = {
      ec: 'recipe',
      ea: 'click',
      el: category?.name,
      recipe_category: category?.name,
    };
    const clickCategoryContainer = () => {
      TrackingService.clickSeeCategoryRecipe({
        category: category?.name,
      });
    };
    const clickSubcategoryContainer = (card: any) => {
      TrackingService.clickSeeSubcategoryRecipe({
        category: category?.name,
        subcategory: card?.title,
      });
    };
    return (
      <CategorySubcategory
        categoryData={categoryDataDynamic}
        cardData={subcategoryCardsData}
        tracking={categoryTrackingData}
        categoryCardVariation="object"
        data-test="recipe-category-subcategory"
        trackingEvents={{
          clickCategory: clickCategoryContainer,
          clickSubcategory: clickSubcategoryContainer,
        }}
        isOdd={!!(index % 2 === 0)}
      />
    );
  };

  return (
    <>
      <div className="max-w-6xl mx-auto">
        <Breadcrumbs
          titles={breadcrumbsCMSData}
          pageName={breadcrumbsCMSData?.recipesText?.value}
        />
        {!heroCarouselData && <Spacer />}
        <div className="mx-auto flex flex-col items-center md:mb-40">
          {heroCarouselData && (
            <>
              <SliderCarousel
                bannerMasterbrands={heroCarouselData?.bannerMasterbrands}
                banners={heroCarouselData?.banners}
                isForBanners
                type="carousel"
              />
              <Spacer />
            </>
          )}
          {anchorNavigations && anchorNavigations?.length > 0 && (
            <AnchorNavigation anchorNavigations={anchorNavigations} />
          )}
        </div>
        {introData && (
          <>
            <IntroText
              super={introData.super ?? ''}
              title={introData.title ?? ''}
              description={introData.description ?? ''}
            />
            <Spacer />
          </>
        )}
        {landingPage?.recipeLandingPage?.recipeCategories?.map((category: any, index: any) => (
          <CategorySubcategoryWrapper
            key={category.id ?? `subcategory-${index}`}
            category={category}
            data-test="cat-subcat"
            index={index}
          />
        ))}
        <hr />
        {dynamicPageComponents && (
          <TemplateMapper
            components={dynamicPageComponents}
            copy={[]}
            recipeCopy={recipeCopy}
            difficultyCopy={difficultyCopy}
            searchItemLabels={searchItemLabels}
            searchData={searchOverlayDataExtended}
          />
        )}
      </div>
    </>
  );
}

export default ClientRecipesPage;
