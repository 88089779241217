import RootLayoutClient from 'components/PageLevelComponents/ClientLayout';
import ClientRecipesPage from 'components/PageLevelComponents/ClientRecipesPage';
import { LOCALE_DEFAULT } from 'config/config';
import { RecipesPageController } from 'server/controller/page/recipes/recipesPageController';
import { RecipesPageResource } from 'server/resource/page/recipesPageResource';

const RootPage = (props: RecipesPageResource) => {
  const {
    metaData,
    searchItemLabels,
    recipeCopy,
    breadcrumbsCMSData,
    footerNavData,
    footerImage,
    usercentricsIds,
    locale,
    searchOverlayData,
    countrySelectorData,
    newsletterData,
    contactUsUrl,
    difficultyCopy,
    getHeaderNav,
    getMobileNavData,
    popularSearchData,
    cardCarouselCMSData,
    recallData,
    landingPage,
    dynamicPageComponents,
    pathTranslations,
  } = props;

  return (
    <RootLayoutClient
      globalMetadata={metaData}
      getHeaderNav={getHeaderNav}
      getMobileNavData={getMobileNavData}
      popularSearchData={popularSearchData}
      footerNavData={footerNavData}
      footerImage={footerImage}
      usercentricsIds={usercentricsIds}
      locale={locale}
      searchOverlayData={searchOverlayData}
      countrySelectorData={countrySelectorData}
      newsletterData={newsletterData}
      contactUsUrl={contactUsUrl}
      difficultyCopy={difficultyCopy as Record<string, string>}
      cardCarouselCMSData={cardCarouselCMSData}
      recallData={recallData}
      pathTranslations={pathTranslations}
    >
      <ClientRecipesPage
        landingPage={landingPage}
        dynamicPageComponents={dynamicPageComponents}
        breadcrumbsCMSData={breadcrumbsCMSData}
        recipeCopy={recipeCopy}
        searchItemLabels={searchItemLabels as Record<string, string>}
        searchOverlayData={searchOverlayData}
        difficultyCopy={difficultyCopy as Record<string, string>}
      />
    </RootLayoutClient>
  );
};

type GetServerSidePropsParams = {
  locale: string;
  defaultLocale: string;
  resolvedUrl: string;
};

export const getServerSideProps = async ({
  locale,
  defaultLocale,
  resolvedUrl,
}: GetServerSidePropsParams) => {
  return RecipesPageController.getCachedRecipesPageData({
    resolvedUrl,
    locale: locale || defaultLocale || LOCALE_DEFAULT,
  });
};

export default RootPage;
